import React from "react";
import LandingHeader from "../components/Headers/LandingPageHeader/LandingPageHeader";
import LandingFooter from "../components/Footers/LandingFooter/LandingFooter";
import LandingFooterVersion1And2 from "../components/Footers/LandingFooter/LandingFooterVersion1And2";
import "./main.scss";
import "./landing.scss";
import useGtmPageChange from "../hooks/useGtmPageChange";
import generateUuId from "../utils/generateUuId";

interface LandingLayoutProps {
  children: React.ReactNode;
  mainClassName?: string;
  lpLayout: "lp_with_video" | "version1" | "version2" | "expired_trial";
  hasFreeTrialBtn?: boolean;
}
const LandingLayout: React.FC<LandingLayoutProps> = ({
  children,
  mainClassName = "",
  lpLayout,
  hasFreeTrialBtn = true,
}) => {
  const uuid = generateUuId();

  useGtmPageChange(uuid);

  const LP_FOOTER_BY_LAYOUT = {
    lp_with_video: <LandingFooter />,
    version1: <LandingFooterVersion1And2 />,
    version2: <LandingFooterVersion1And2 />,
    expired_trial: <></>,
  };

  return (
    <>
      <LandingHeader hasFreeTrialBtn={hasFreeTrialBtn} />
      <div>
        <main className={mainClassName}>{children}</main>
      </div>
      {LP_FOOTER_BY_LAYOUT[lpLayout]}
    </>
  );
};

export default LandingLayout;
