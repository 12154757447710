import React from "react";
import LandingLayout from "../../layouts/landing";
import LandingVideoContainer from "../../containers/LandingVideoContainer/LandingVideoContainer";
import LandingPageFormContainer from "../../containers/LandingPageFormContainer/LandingPageFormContainer";
import { ILandingPageFormP, ILandingPageLayout } from "@types";

type DataProps = {
  template: {
    landingPageLayout: ILandingPageLayout;
    landingPageFormP: ILandingPageFormP;
  };
};

const LandingPageWithVideoContainer: React.FC<DataProps> = ({
  template: { landingPageFormP, landingPageLayout },
}) => {
  return (
    <LandingLayout lpLayout={landingPageLayout.lpLayout}>
      <LandingVideoContainer headerData={landingPageLayout} />
      <LandingPageFormContainer
        landingPageFormP={landingPageFormP}
        content={landingPageLayout.lpContent}
      />
    </LandingLayout>
  );
};

export default LandingPageWithVideoContainer;
