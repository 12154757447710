import * as React from "react";
import { memo, useEffect } from "react";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import loadVideo from "../../../assets/util-scripts/load-video";
import VideoButton from "../../Buttons/VideoButton/VideoButton";
import { globalModalTrigger } from "../../../utils/globalModalTrigger";
import useModalsTrigger from "../../../sp-state/modals/useModalsTriggerStore";

import styles from "../Video.module.scss";

interface VideoPlayerProps {
  videoId: string;
  videoImageAlt: string;
  videoBackgroundImage: IGatsbyImageData;
  buttonColor?: "blue" | "white";
  showButton?: boolean;
  allowedPlay?: boolean;
  autoPlay?: boolean;
  withModal?: boolean;
  className?: string;
}
const VideoPlayer: React.FC<VideoPlayerProps> = ({
  videoId,
  videoBackgroundImage,
  videoImageAlt,
  buttonColor = "blue",
  showButton = true,
  allowedPlay = true,
  autoPlay = false,
  withModal = true,
  className = "",
}) => {
  useEffect(() => {
    if (!withModal) {
      const script1 = document.createElement("script");
      const script2 = document.createElement("script");

      script1.src = `https://fast.wistia.com/embed/medias/${videoId}.jsonp`;
      script1.async = true;

      script2.src = "https://fast.wistia.com/assets/external/E-v1.js";
      script2.async = true;

      document.body.appendChild(script1);
      document.body.appendChild(script2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const videoClasses = `wistia_embed wistia_async_${videoId} videoFoam=true autoPlay=${autoPlay}`;
  const { setModalTrigger, validModalTriggers } = useModalsTrigger(
    state => state
  );

  const handlePlay = () => {
    if (allowedPlay && videoId) {
      loadVideo(videoId);
    } else {
      globalModalTrigger(
        setModalTrigger,
        validModalTriggers,
        "sitewide-generic-gated"
      );
    }
  };

  return withModal ? (
    <div
      className={styles.testimonialVideoContainer}
      style={{ position: "relative", display: "flex" }}
      onClick={handlePlay}
    >
      {videoBackgroundImage && (
        <GatsbyImage
          image={videoBackgroundImage}
          alt={videoImageAlt || ""}
          className={styles.videoBackgroundImage}
        />
      )}
      {showButton && videoId && (
        <VideoButton
          buttonColor={`primary--${buttonColor}-bg`}
          iconWhite={buttonColor === "blue"}
          isClass={false}
          videoID={videoId}
        />
      )}
    </div>
  ) : (
    <div className={className}>
      <div className={videoClasses} />
    </div>
  );
};

export default memo(VideoPlayer);
