import React from "react";
import Loading from "../Loading/Loading";
import styles from "./ButtonLoading.module.scss";

const ButtonLoading: React.FC<{
  className?: string;
  width?: string;
  height?: string;
}> = ({ className = "", width, height }) => {
  const buttonLoadingContainerClasses = `${styles.btnLoading} ${className}`;

  return (
    <div
      data-testid="div:ButtonLoading"
      className={buttonLoadingContainerClasses}
    >
      <Loading className={styles.loadingIcon} width={width} height={height} />
    </div>
  );
};

export default ButtonLoading;
