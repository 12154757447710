import React from "react";
import styles from "./LandingFooter.module.scss";

const LandingFooterVersion1And2 = () => {
  return (
    <footer className={styles.lpFooterVersion1}>
      <div className={styles.lpCopyrightContainerVersion1}>
        <p>
          Copyright © {new Date().getFullYear()} SimplePractice, LLC. All rights
          reserved
        </p>
        <div>
          <ul>
            <li>
              <a href="/terms">Terms</a>
            </li>
            <li>
              <a href="/privacy">Privacy Policy</a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default LandingFooterVersion1And2;
