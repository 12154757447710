import React, { useState } from "react";
import AlertMessage from "../../components/Forms/AlertMessage/AlertMessage";
import PrimaryButton from "../../components/Buttons/PrimaryButton/PrimaryButton";
import { ILandingPageFormP } from "@types";
import { isValidEmail } from "../../utils/validations";

import styles from "./LandingPageFormContainer.module.scss";
import formStyles from "./LandingPageForm.module.scss";
import "./LandingPageFormContainer.scss";
import { useSignUpFormNavigate } from "@hooks/useSignUpFormNavigate";

type LandingPageFormPProps = {
  landingPageFormP: ILandingPageFormP;
  content: string;
};

const LandingPageFormP: React.FC<LandingPageFormPProps> = ({
  landingPageFormP,
  content,
}) => {
  const [email, setEmail] = useState("");
  const signUpFormNavigate = useSignUpFormNavigate();
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { lpFormHeader, lpFormCtaCopy, lpFormDisclosure, lpFormSubcopy } =
    landingPageFormP;

  const handleSubmit = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();

    if (email.length === 0) {
      setHasError(true);
      return setErrorMessage("Your Email is required.");
    }
    if (!isValidEmail(email)) {
      setHasError(true);
      return setErrorMessage("Your Email is an invalid format.");
    }

    event.preventDefault();

    signUpFormNavigate(email);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;
    setEmail(value);
  };

  return (
    <div className={`lpFullWidth ${styles.formContainer}`}>
      <div>
        <div
          className={styles.formList}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
      <div className={formStyles.formCard}>
        <div className={formStyles.formCardForm}>
          <h3 className={formStyles.formCardTitle}>{lpFormHeader}</h3>
          <p className={formStyles.formSubCopy}>{lpFormSubcopy}</p>
          <input
            className={formStyles.formInputEmail}
            name="email"
            type="email"
            value={email}
            onChange={handleChange}
            placeholder="Email *"
          />
          <PrimaryButton
            buttonText={lpFormCtaCopy}
            onClickHandler={handleSubmit}
          />
          <p className={formStyles.formDisclosure}>{lpFormDisclosure}</p>

          {hasError && (
            <AlertMessage
              message={errorMessage}
              onClose={() => setHasError(false)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default LandingPageFormP;
