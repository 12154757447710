import * as React from "react";
import { graphql, PageProps } from "gatsby";
import LandingPageWithVideoContainer from "../../containers/LandingPageWithVideoContainer/LandingPageWithVideoContainer";
import LandingPageVersion1Container from "../../containers/LandingPageVersion1Container/LandingPageVersion1Container";
import LandingPageVersion2Container from "../../containers/LandingPageVersion2Container/LandingPageVersion2Container";
import LandingPageExpiredTrialContainer from "../../containers/LandingPageExpiredTrialContainer/LandingPageExpiredTrialContainer";
import { ILandingPageFormP, ILandingPageLayout } from "@types";
import { YoastSEOType } from "../../assets/Types";

type DataProps = {
  wpPage: {
    seo: YoastSEOType;
    template: {
      landingPageLayout: ILandingPageLayout;
      landingPageFormP: ILandingPageFormP;
    };
  };
};

const LandingPage: React.FC<PageProps<DataProps>> = ({ data }) => {
  const { wpPage } = data;
  const { template } = wpPage;
  const LP_TEMPLATE_BY_LAYOUT = {
    lp_with_video: <LandingPageWithVideoContainer template={template} />,
    version1: <LandingPageVersion1Container template={template} />,
    version2: <LandingPageVersion2Container template={template} />,
    expired_trial: <LandingPageExpiredTrialContainer template={template} />,
  };

  return LP_TEMPLATE_BY_LAYOUT[template.landingPageLayout.lpLayout];
};

export const LANDING_PAGE_QUERY = graphql`
  query LandingPageQuery($id: String!) {
    wpPage(id: { eq: $id }) {
      ...YoastSEOFragment
      template {
        ... on WpTemplate_LandingPage {
          templateName
          landingPageFormP {
            lpFormSubcopy
            lpFormHeader
            lpFormDisclosure
            lpFormCtaCopy
            expiredTrialTryAgain
            expiredTrial
            expiredTrialOptions {
              optionValue
            }
          }
          landingPageLayout {
            lpContent
            lpHeader
            lpLayout
            lpShowTrialButton
            lpSubcopy
            lpBadgeText
            lpBackgroundColor
            videoId
            lpImage {
              altText
              localFilePNG: localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 1200
                    placeholder: BLURRED
                    formats: [WEBP, JPG, AUTO]
                  )
                }
              }
              localFileWEBP: localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 1200
                    placeholder: BLURRED
                    formats: [WEBP, JPG, AUTO]
                  )
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default LandingPage;
