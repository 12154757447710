import React, { useState } from "react";
import { useQueryParam, StringParam } from "use-query-params";
import AlertMessage from "../../components/Forms/AlertMessage/AlertMessage";
import ButtonLoading from "../../components/Loadings/ButtonLoading/ButtonLoading";
import Footer from "../../components/Footers/LandingFooter/LandingFooter";
import LandingLayout from "../../layouts/landing";
import PrimaryButton from "../../components/Buttons/PrimaryButton/PrimaryButton";
import { isValidEmail } from "../../utils/validations";
import { logger } from "../../utils/logger";
import { ILandingPageFormP, ILandingPageLayout } from "@types";
import { ExpiredTrialSubmit } from "src/assets/Types/Requests.type";
import SPFetch from "../../services/SPFetch.class";

import styles from "./LandingPageExpiredTrialContainer.module.scss";
import formStyles from "../LandingPageFormContainer/LandingPageForm.module.scss";

type DataProps = {
  template: {
    landingPageLayout: ILandingPageLayout;
    landingPageFormP: ILandingPageFormP;
  };
};
const initialState = { tryAgain: "yes", checkedOptions: [] as string[] };

const LandingPageExpiredTrialContainer: React.FC<DataProps> = ({
  template: {
    landingPageFormP: {
      lpFormSubcopy,
      lpFormCtaCopy,
      expiredTrialOptions,
      expiredTrialTryAgain,
    },
    landingPageLayout: { lpHeader, lpSubcopy, lpLayout },
  },
}) => {
  const [data, setData] = useState(initialState);
  const [spUserEmail] = useQueryParam("sp_user_email", StringParam);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showThankYou, setShowThankYou] = useState(false);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name, value, checked },
    } = event;
    const newCheckedOptions = checked
      ? [...data.checkedOptions, value]
      : data.checkedOptions.filter(option => option !== value);

    setData(current => {
      return {
        ...current,
        [name]: newCheckedOptions,
      };
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const {
      target: { name, value },
    } = event;
    setData(current => {
      return {
        ...current,
        [name]: value,
      };
    });
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!spUserEmail || spUserEmail?.length === 0) {
      setHasError(true);
      return setErrorMessage("Your Email is required.");
    }
    if (!isValidEmail(spUserEmail)) {
      setHasError(true);
      return setErrorMessage("Your Email is an invalid format.");
    }

    try {
      setLoading(true);

      const formData: ExpiredTrialSubmit = {
        EXP_SURVEY_RESPONSE_1: data.checkedOptions,
        EXP_SURVEY_RESPONSE_2: data.tryAgain,
        email: spUserEmail,
      };

      const { status } = await SPFetch.post(
        `${process.env.GATSBY_WS_URL}/api/iterable/userSubmit/${process.env.GATSBY_ITERABLE_LIST_ID}`,
        formData
      );
      if (status === 200) {
        setShowThankYou(true);
      }
    } catch (error) {
      logger(error.response);
    } finally {
      setLoading(false);
    }
  };

  const options = expiredTrialOptions.map((element, index) => {
    return (
      <div key={index} className={styles.lpOptionContainer}>
        <input
          name="checkedOptions"
          type="checkbox"
          value={element?.optionValue}
          onChange={handleCheckboxChange}
        />
        <p>{element?.optionValue}</p>
      </div>
    );
  });

  return (
    <LandingLayout mainClassName={styles.lpMainContainer} lpLayout={lpLayout}>
      <div className={`lpFullWidth ${styles.lpContent}`}>
        <div className={styles.lpGrid}>
          <div className={styles.lpHeader}>
            <h2 className={styles.lpTitle}>{lpHeader}</h2>
            <p>{lpSubcopy}</p>
          </div>

          <div className={styles.lpColumn2}>
            <div
              className={`${formStyles.formCardForm} ${styles.lpFormCardFormTrial}`}
            >
              <p className={styles.lpFormTitle}>{lpFormSubcopy}</p>
              {options}

              <div className={styles.lpExpiredTrialOptionSelect}>
                <p>{expiredTrialTryAgain}</p>
                <select
                  name="tryAgain"
                  value={data.tryAgain}
                  onChange={handleChange}
                >
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
              </div>

              {!showThankYou ? (
                <>
                  {!loading ? (
                    <PrimaryButton
                      buttonText={lpFormCtaCopy}
                      onClickHandler={handleSubmit}
                    />
                  ) : (
                    <ButtonLoading className={styles.loadingButton} />
                  )}
                </>
              ) : (
                <div className={styles.lpThankYou}>
                  Submission successful. Thank you for your feedback
                </div>
              )}

              {hasError && (
                <AlertMessage
                  message={errorMessage}
                  onClose={() => setHasError(false)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </LandingLayout>
  );
};

export default LandingPageExpiredTrialContainer;
