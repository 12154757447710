import React from "react";
import { Link } from "gatsby";
import SimplePracticeLogoBlue from "../../SVGIcons/SimplePracticeLogos/SimplePracticeLogoBlue";
import styles from "./LandingPageHeader.module.scss";

const LandingPageHeader: React.FC<{ hasFreeTrialBtn?: boolean }> = ({
  hasFreeTrialBtn = true,
}) => {
  return (
    <header
      data-testid="header:LandingPageHeader"
      className={`lpFullWidth ${styles.lpHeader}`}
    >
      <Link to="/">
        <SimplePracticeLogoBlue />
      </Link>

      <div>
        <a
          className={styles.lpSignIn}
          href="https://secure.simplepractice.com/users/sign_in"
          target="_blank"
          rel="noreferrer noopener"
        >
          Sign In
        </a>
        {hasFreeTrialBtn && (
          <Link
            data-testid="a:hasFreeTrialBtn:LandingPageHeader"
            className={styles.lpTrialButton}
            to="/sign-up/"
          >
            30 Day Free Trial
          </Link>
        )}
      </div>
    </header>
  );
};

export default LandingPageHeader;
