import React, { useState } from "react";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import AlertMessage from "../../components/Forms/AlertMessage/AlertMessage";
import ButtonLoading from "../../components/Loadings/ButtonLoading/ButtonLoading";
import LandingLayout from "../../layouts/landing";
import PrimaryButton from "../../components/Buttons/PrimaryButton/PrimaryButton";
import { IterableDataType } from "../../assets/Types";
import { ILandingPageFormP, ILandingPageLayout } from "@types";
import { isAppleFamilyDevices, isValidEmail } from "../../utils/validations";
import { logger } from "../../utils/logger";

import styles from "./LandingPageVersion1Container.module.scss";
import formStyles from "../LandingPageFormContainer/LandingPageForm.module.scss";
import { iterableSubmit } from "../../utils/iterableSubmit";
import { useSpecialties } from "../../hooks/useSpecialties";

type DataProps = {
  template: {
    landingPageLayout: ILandingPageLayout;
    landingPageFormP: ILandingPageFormP;
  };
};

type DataToSubmit = {
  firstName: string;
  email: string;
  specialty: string;
};

const initialState: DataToSubmit = {
  firstName: "",
  email: "",
  specialty: "no_selection",
};

const LandingPageVersion1: React.FC<DataProps> = ({
  template: {
    landingPageFormP: {
      lpFormHeader,
      lpFormSubcopy,
      lpFormCtaCopy,
      lpFormDisclosure,
    },
    landingPageLayout: {
      lpBadgeText,
      lpHeader,
      lpSubcopy,
      lpImage,
      lpContent,
      lpLayout,
    },
  },
}) => {
  const [showThankYou, setShowThankYou] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(initialState);

  const { specialties } = useSpecialties(0);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const {
      target: { name, value },
    } = event;
    setData(current => {
      return {
        ...current,
        [name]: value,
      };
    });
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (data.email.length === 0) {
      setHasError(true);
      return setErrorMessage("Your Email is required.");
    }
    if (!isValidEmail(data.email)) {
      setHasError(true);
      return setErrorMessage("Your Email is an invalid format.");
    }

    try {
      setLoading(true);

      const formData: IterableDataType = {
        Digioh_Source: window.location.href,
        FIRST_NAME: data.firstName,
        GLOBAL_PROFESSION: data.specialty,
        digioh_source: window.location.pathname.replaceAll("/", ""),
        email: encodeURI(data.email),
        eventName: "newUserSignup",
        SIGN_UP_SOURCE: "ListAPI",
        submitURL: window.location.href,
      };

      const { status } = await iterableSubmit(formData);
      if (status === 200) {
        setShowThankYou(true);
      }
    } catch (error) {
      logger(error.response);
    } finally {
      setLoading(false);
    }
  };

  const image = getImage(
    isAppleFamilyDevices() ? lpImage?.localFilePNG : lpImage?.localFileWEBP
  );
  const gridClasses = showThankYou
    ? `${styles.lpGrid} ${styles.submited}`
    : styles.lpGrid;

  return (
    <LandingLayout mainClassName={styles.lpMainContainer} lpLayout={lpLayout}>
      <div className={`lpFullWidth ${styles.lpDivContainer}`}>
        <div className={styles.lpHeader}>
          <p className={styles.lpBadge}>{lpBadgeText}</p>
          <h2 className={styles.lpTitle}>{lpHeader}</h2>
          <p>{lpSubcopy}</p>

          {showThankYou && (
            <div className={styles.lpThankYou}>
              <h2>Thank You!</h2>
              <p>Check your email to receive your free e-book.</p>
            </div>
          )}
        </div>

        <div className={gridClasses}>
          <div className={styles.lpGridImage}>
            <GatsbyImage image={image} alt={lpImage.altText || ""} />
          </div>

          {!showThankYou && (
            <>
              <div
                className={styles.lpGridContent}
                dangerouslySetInnerHTML={{ __html: lpContent }}
              />

              <div className={`${styles.lpGridForm} ${formStyles.formCard}`}>
                <div className={formStyles.formCardForm}>
                  <h3 className={formStyles.formCardTitle}>{lpFormHeader}</h3>
                  <p className={formStyles.formSubCopy}>{lpFormSubcopy}</p>
                  <input
                    className={formStyles.formInputEmail}
                    name="firstName"
                    type="text"
                    value={data.firstName}
                    onChange={handleChange}
                    placeholder="First Name"
                  />
                  <input
                    className={formStyles.formInputEmail}
                    name="email"
                    type="email"
                    value={data.email}
                    onChange={handleChange}
                    placeholder="Email *"
                  />
                  <select
                    className={formStyles.formSelect}
                    name="specialty"
                    value={data.specialty}
                    onChange={handleChange}
                  >
                    <option value="no_selection">Select your specialty:</option>
                    {specialties.map(choice => (
                      <option key={choice.id} value={choice.attributes.name}>
                        {choice.attributes.name}
                      </option>
                    ))}
                  </select>

                  {loading ? (
                    <ButtonLoading className={styles.loadingButton} />
                  ) : (
                    <PrimaryButton
                      buttonText={lpFormCtaCopy}
                      onClickHandler={handleSubmit}
                    />
                  )}
                  <p className={formStyles.formDisclosure}>
                    {lpFormDisclosure}
                  </p>

                  {hasError && (
                    <AlertMessage
                      message={errorMessage}
                      onClose={() => setHasError(false)}
                    />
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </LandingLayout>
  );
};

export default LandingPageVersion1;
