import React from "react";
import loadingImg from "../../../assets/images/sp-loading.gif";

const Loading: React.FC<{
  className?: string;
  width?: string;
  height?: string;
}> = ({ className = "", width = "24px", height = "24px" }) => {
  return (
    <img
      data-testid="img:Loading"
      className={className}
      src={loadingImg}
      height={height}
      width={width}
      alt=""
    />
  );
};

export default Loading;
