import React from "react";
import HipaaLogo from "../../../assets/images/hipaa.png";
import NortonLogo from "../../../assets/images/norton.png";
import TrustwaveLogo from "../../../assets/images/trustwave.jpeg";

import styles from "./LandingFooter.module.scss";

const LandingFooter = () => {
  return (
    <footer className={`lpFullWidth ${styles.lpFooterWithBadges}`}>
      <div className={styles.lpCopyrightContainer}>
        <p>
          Copyright © {new Date().getFullYear()} SimplePractice, LLC. All rights
          reserved
        </p>
        <div>
          <ul>
            <li>
              <a href="/terms">Terms</a>
            </li>
            <li>
              <a href="/privacy">Privacy Policy</a>
            </li>
          </ul>
        </div>
      </div>

      <div className={styles.lpSecurityIcons}>
        <img src={NortonLogo} alt="Norton logo" />
        <img src={HipaaLogo} alt="Hipaa logo" />
        <img src={TrustwaveLogo} alt="TrustWave logo" />
      </div>
    </footer>
  );
};

export default LandingFooter;
