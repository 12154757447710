import React from "react";
import { getImage } from "gatsby-plugin-image";
import VideoPlayer from "../../components/Videos/VideoPlayer/VideoPlayer";
import { ILandingPageLayout } from "@types";
import styles from "./LandingVideoContainer.module.scss";

const LandingVideoContainer: React.FC<{ headerData: ILandingPageLayout }> = ({
  headerData: { lpHeader, videoId, lpImage, lpSubcopy },
}) => {
  const hasSubcopy = lpSubcopy?.length > 0;
  const image = getImage(lpImage?.localFileWEBP);

  return (
    <section className={`lpFullWidth ${styles.lpVideoSection}`}>
      <div className={styles.lpVideoContainer}>
        <div className={styles.lpVideoContent}>
          <h2 className={styles.lpVideoTitle}>{lpHeader}</h2>
          {hasSubcopy && (
            <div className={styles.lpVideoSubcopy}>{lpSubcopy}</div>
          )}
        </div>

        <VideoPlayer
          className={styles.videoWrapper}
          withModal={false}
          videoId={videoId}
          autoPlay
          videoBackgroundImage={image}
          videoImageAlt={lpImage?.altText}
        />
      </div>
    </section>
  );
};

export default LandingVideoContainer;
